<script setup lang="ts">
import { CmsBlockProductSlider } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockProductSlider;
}>();

const route = useRoute();

watch(
  () => route.path,
  (value, oldValue, onCleanup) => {
    useHead({
      bodyAttrs: {
        class: (value === '/') ? 'has-stage' : ''
      },
    });
  },
  {immediate: true}
);
</script>
<template>
  <div class="s-gutter">
    <div class="c-width-narrow">
      <CmsGenericElement
        v-for="slot in content.slots"
        :key="`${slot.versionId}-${slot.blockId}`"
        :content="slot"
      />
    </div>
  </div>
</template>
